import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React, { forwardRef, Ref, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { clubDetail } from 'configFiles/roles'
import Box from "@material-ui/core/Box";
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { widgets, colors, } from 'common/styles/configLayout';
import FormikField from "components-lib/FormikField/FormikField";
import Modal from "components-lib/Modal/Modal";
import DocModal from "components-lib/Modal/DocModal";
import { DropzoneArea } from 'material-ui-dropzone';
import Typography from "@material-ui/core/Typography"
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CheckIcon from '@material-ui/icons/Check';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import LockIcon from '@material-ui/icons/Lock';
import * as axios from "axios";
import moment from "moment";
import { useSnackbar } from 'notistack';
import PROFILE_DOCUMENT_UPLOAD from 'queries/DocumentsQueries/profileDocumentUpload';
import PROFILE_DOCUMENT_DELETE from 'queries/DocumentsQueries/profileDocumentDelete';
import { gql, useMutation } from '@apollo/client';
import ClubDocumentsEnum from 'common/enums/ClubDocumentsEnum';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from "formik";
import CLUB_MEMBER from 'queries/MembersQueries/clubMember';
import { getApolloClient } from "configFiles/apollo";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import NoSsr from '@material-ui/core/NoSsr';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import styled from 'styled-components';
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import MenuItem from '@material-ui/core/MenuItem';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Remove';
import CATEGORIES_BY_MEMBER from "queries/ClubsQueries/categoriesByMember";
import MEMBER_LICENSE_OPTIONS from "queries/LicensesQueries/memberLicenseOptions";
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import { StyledTableCell, StyledTableRow } from 'common/styles/muiDesign'
import StatusTextField from "components-lib/StatusTextField/StatusTextField";
import UCI_SYNC_MEMBER from 'queries/MembersQueries/uciSyncMember';
import RoleEnum from 'common/enums/RoleEnum';


const useStyles = makeStyles((theme) => styles(theme))

export const LicenseDetailTable = (props) => {
    const { intl, cardDetail, role, history, rows, refetch, status, onDownloadPersonLicense } = props
    const classes = useStyles();
    
    const { enqueueSnackbar } = useSnackbar();

    const [uciSyncMember] = useMutation(UCI_SYNC_MEMBER);
    const handleUciSyncMember = (memberId) => {
        uciSyncMember({
            variables: {
                memberId: memberId,
                background: false
            },
        }).then((response) => {
            enqueueSnackbar('Žiadosť o zápis člena do UCI prebehla úspešne', { variant: 'success' });
            history.push(`/admin${generatePath(paths.licenses.list)}`);
            refetch();
        }).catch((err) => {
            console.log('[Error]: ', err);
        });
    }

    const handleClick = (id) => {
        history.push(`/admin/members/detail/${id}`);
    };
    
    return (
        <TableContainer style={{ borderRadius: "4px" }}>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow >
                        <StyledTableCell>Meno</StyledTableCell>
                        <StyledTableCell>UCI ID</StyledTableCell>
                        <StyledTableCell>Dátum narodenia</StyledTableCell>
                        <StyledTableCell>Funkcia</StyledTableCell>
                        <StyledTableCell>Odvetvie</StyledTableCell>
                        <StyledTableCell>Kategória</StyledTableCell>
                        {(status !== "Pending" && status !== "Paid" && !cardDetail) && <StyledTableCell>Spracovaná</StyledTableCell>}
                        {(role === RoleEnum.ADMIN || role === RoleEnum.STATUTORY) && 
                            <StyledTableCell></StyledTableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.values(rows)?.map((item, idx) => (
                        <TableRow 
                            key={item.type}
                            style={{ 
                                backgroundColor: (!item?.processedAt && status !== 'Pending' && status !== 'Paid') ? colors.errorBackground : 
                                item.hasCardRequest === true && 'rgba(28, 202, 255, 0.2)'
                            }}
                        >
                            <StyledTableCell component="th" scope="row" onClick={ () => handleClick(item?.member.id) }>
                                <div style={{ cursor: "pointer", color: 'rgba(28, 202, 255, 0.95)' }}>
                                    {item?.member.person.profile.firstName} {item?.member.person.profile.lastName}
                                </div>
                            </StyledTableCell>
                            <StyledTableCell>
                                {(item?.member?.uciId) ? (item?.member?.uciId) : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                                {(item?.member.person.profile.birth) ? (moment(item?.member.person.profile.birth).format("DD.MM.YYYY")) : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                                {item?.function?.name}
                            </StyledTableCell>
                            <StyledTableCell>
                                {(item?.disciplines?.length > 0) ? item?.disciplines?.map(disc => disc.discipline.name).join(", ") : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                                {(item?.disciplines) ? (item?.disciplines?.map(cat => cat.category.name)).join(", ") : '-'}
                            </StyledTableCell>
                            {(status !== "Pending" && status !== "Paid" && !cardDetail) &&
                                <StyledTableCell>
                                    {(item?.processedAt)
                                        ? (moment(item?.processedAt).format("DD.MM.YYYY HH:mm"))
                                        : (role === RoleEnum.ADMIN && status === 'PartialReady')
                                            ? <Button round color="danger" onClick={() => handleUciSyncMember(item?.member?.id)}><GetAppIcon />Opätovne vystaviť</Button>
                                            : <StatusTextField intl={intl} status={'NotLicensed'} />
                                    }
                                </StyledTableCell>
                            }
                            {(role === RoleEnum.ADMIN || role === RoleEnum.STATUTORY || role === RoleEnum.PRESIDENT) &&
                                <StyledTableCell>
                                    <Tooltip title={"Stiahnuť PDF na tlač"}>
                                        <GetAppIcon 
                                            className={classes.circle} 
                                            onClick={() => onDownloadPersonLicense( cardDetail ? item?.downloadUrl : item?.cardFileUrl)} 
                                        />
                                    </Tooltip>
                                </StyledTableCell>
                            }
                        </TableRow>
                    ))}
                </TableBody>
            </Table >
        </TableContainer >
    )
}

export default injectIntl(LicenseDetailTable);